import { Demo } from '../../components/Demo/Demo'

export default function DemoPage() {
  return (
    <>
    <div>
      <Demo/>
    </div>
    </>
  )
}